'use client';
import { useAccount, useChainId, usePublicClient, useWalletClient } from 'wagmi';

// Attempting interface wrapper around old API
export function useWeb3React() {
  const account = useAccount();
  const chainId = useChainId();
  const provider = usePublicClient({ chainId });
  const signer = useWalletClient();

  return {
    chainId,
    account: account?.address,
    // account: '0xe65560d56d70375953ebae877384eb669d33297e',
    provider,
    signer: signer?.data,
    ...account,
  };
}
